const data = [
  {
    id: 1,
    title: "bio",
    content: (
      <div className="bio-text">
        <p>
          /** Dedicated Software developer with 4 years of commercial
          experience, most recently specializing in front-end web development.
          Proficiency in a wide range of programming languages and
          methodologies. Experience across a varied range of industries,
          including access control, aviation and health care. */
        </p>
        <p>
          /** Currently focused on small business web applications, primarily
          using JavaScript, React, and Headless CMS. */
        </p>
      </div>
    ),
  },
  {
    id: 2,
    title: "toolkit",
    content: (
      <div>
        <ul className="list-group-interest list-group">
          <li>HTML5 </li>
          <li>CSS, Sass, Gulp</li>
          <li>Bootstrap</li>
          <li>JavaScript, jQuery</li>
          <li>TypeScript</li>
          <li>React.js</li>
          <li>NPM/Yarn</li>
          <li>Webpack</li>
          <li>Babel</li>
          <li>WordPress</li>
          <li>WooCommerce</li>
          <li>shopify</li>
          <li>Rest API</li>
          <li>MySql</li>
          <li>PHP</li>
          <li>GitHub</li>
          <li>Asana</li>
          <li>Adobe Photoshop</li>
          <li>Adobe illustrator</li>
          <li>Adobe XD</li>
          <li>Figma</li>
        </ul>
      </div>
    ),
  },
  {
    id: 3,
    title: "experience",
    content: (
      <div className="experience gap-3 d-flex row      ">
        <div className="d-flex row gap-2 bio-text">
          <h3> Product Manager/UX Designer - Aero Dental Clinic </h3>
          <span className="small"> 2023 - Present, Pennsylvania, USA</span>
          <h3>
            {" "}
            Product Manager/UX Designer - Brandywine Aviation & Maintenance{" "}
          </h3>
          <span className="small"> 2021 - Present, Pennsylvania, USA</span>
          <p>
            /** Designed and created a robust content management system within
            the WIX framework, empowering clients to manage their website
            content independently. */
          </p>
        </div>
        <div className="d-flex row gap-2 bio-text">
          <h3> Software Developer - EOS Co. </h3>
          <span className="small"> 2017 - 2019, Iran</span>
          <p>
            /** Worked within a team to design and implement a Smart Parking
            Meter payment system using React. */
          </p>
        </div>
        <div className="d-flex row gap-2 bio-text">
          <h3> UI / UX Designer - EOS Co. </h3>
          <span className="small"> 2012 - 2017, Iran</span>
          <p>
            /** Developed wireframes and prototypes, iterating and collaborating
            with cross-functional teams for feasibility. */
          </p>
        </div>
      </div>
    ),
  },
];

export default data;
